<template>
  <div class="mine">
    <!-- 头像卡片 -->
    <div class="mine-card">
      <van-row>
        <van-col span="6" style="width: 1rem; height: 1rem">
          <van-image
            fit="cover"
            round
            width="1rem"
            height="1rem"
            :src="user.avatar ? user.avatar : userHead"
            @click="goUpdateUser"
          />
        </van-col>
        <van-col span="15">
          <div
            v-if="JSON.stringify(user) == '{}'"
            class="mine-card-title"
            @click="toUrl('/login', { redirect })"
          >
            未登录
          </div>
          <div v-else class="mine-card-title">
            <span v-if="user.nickname">{{ user.nickname }}</span>
            <span>{{ user.tel }}</span>
          </div>
          <!-- <div class="mine-card-title" @click="toUrl('/login', { redirect })">
						<span>海绵宝宝</span>
						<span>18888888888</span>
					</div> -->
        </van-col>
        <div class="mine-icon">
          <div class="mine-icon-item" @click="collect">
            <img src="../../assets/mine/shoucang.png" alt="" />
            <span>收藏</span>
          </div>
          <div class="mine-icon-item" @click="history">
            <img src="../../assets/mine/zuji.png" alt="" />
            <span>足迹</span>
          </div>
          <div class="mine-icon-item" @click="coupon">
            <img src="../../assets/mine/CardCoupon.png" alt="" />
            <span>卡券</span>
          </div>

          <div class="mine-icon-item" @click="concern">
            <img src="../../assets/mine/guanzhu.png" alt="" />
            <span>关注</span>
          </div>
        </div>
        <!-- <van-col span="3">
					<van-icon name="arrow" class="mine-card-setting" @click="toUrl('/setting')" />
				</van-col> -->
      </van-row>
    </div>
    <div class="mine-order">
      <div class="myOrder">我的订单</div>
      <div class="listOrderr">
        <div
          v-for="(item, index) in order"
          :key="index"
          class="mine-order-item"
          @click="goMyOrders(index)"
        >
          <img :src="item.url" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <!-- 操作栏-->
    <div class="mine-setting">
      <van-cell-group>
        <van-cell
          icon="scan"
          title="扫码核销"
          @click="examineCode"
          is-link
          v-if="user.is_check == 1"
        />
        <van-cell icon="location-o" title="收货地址" @click="address" is-link />
        <van-cell
          icon="service-o"
          title="联系我们"
          @click="contactUs"
          is-link
        />
        <van-cell icon="manager-o" title="关于我们" @click="aboutUs" is-link />
      </van-cell-group>
    </div>
    <div class="copyright">
      <p>由贰叁伍科技提供技术支持</p>
    </div>

    <!-- <van-popup v-model:show="pass" style="border-radius: 10px;">
			<van-form @submit="onSubmit" class="pass-form">
				<van-field label="原密码" label-width="5rem" size="large" clearable v-model="old_password" name="validator"
					placeholder="请输入密码" :rules="[{ required:true, message: '请输入密码' }]" />
				<van-field label="新密码" size="large" label-width="5rem" clearable v-model="new_password" name="pattern"
					placeholder="请输入账号" :rules="[{ required:true, message: '请输入账号' }]" />
				<div style="margin-top:2rem;">
					<van-button round block type="primary" native-type="submit">提交修改</van-button>
				</div>
			</van-form>
		</van-popup> -->
  </div>
</template>
<script>
import { info } from "../../api/user.js";
import { toUrl } from "@/utils/tools";
import { Toast, Dialog } from "vant";

export default {
  data() {
    return {
      imageUrl: this.url.imageUrl,
      pass: false,
      user: {},
      redirect: window.location.href,
      old_password: "",
      new_password: "",
      order: [
        {
          name: "全部",
          url: require("@/assets/mine/dingdan.png"),
        },
        {
          name: "待付款",
          url: require("@/assets/mine/daifukuan.png"),
        },
        // {
        // 	name: '待发货',
        // 	url: require('@/assets/daifahuo.png')
        // },
        {
          name: "待收货",
          url: require("@/assets/mine/daishouhuo.png"),
        },
        {
          name: "待评价",
          url: require("../../assets/mine/daipingjia.png"),
        },
      ],
      userHead: require("@/assets/userHead.png"),
    };
  },
  mounted() {
    // console.log(this.$store.state)
    this.load();
  },
  methods: {
    // 核销二维码
    examineCode() {
      var that = this;
      // if (isUnionPay()) {
      upsdk.pluginReady(function () {
        upsdk.scanQRCode({
          scanType: ["qrCode"],
          success: function (result) {
            that.$router.push({
              path: "/h5/toQrcode",
              query: {
                code: result,
              },
            });
          },
        });
      });

      // }
    },
    //修改用户信息
    goUpdateUser() {
      this.$router.push({
        path: "/h5/updateUser",
      });
    },
    //我的订单
    goMyOrders(type) {
      console.log(typeof type);
      console.log(type);
      this.$router.push({
        path: "/h5/orderPage",
        query: {
          type: type,
        },
      });
    },
    load() {
      info().then((res) => {
        this.user = res.data.info;
      });
    },
    toUrl(path, param = null) {
      toUrl(path, param);
    },
    modifyPwd() {
      this.pass = !this.pass;
    },
    coupon() {
      this.$router.push({
        path: "/h5/couponList",
      });
    },
    address() {
      this.$router.push({
        path: "/h5/shoppingAddress",
      });
    },
    concern() {
      this.$router.push({
        path: "/h5/concernPage",
      });
    },
    onSubmit() {
      if (this.old_password === this.new_password)
        Toast.fail("新旧密码不能一样");
      modifyPassword({
        new_password: this.new_password,
        old_password: this.old_password,
      }).then((res) => {
        Toast({
          message: res.msg,
        });
        this.$store.commit("SET_TOKEN", "");
        this.$store.commit("SET_IS_LOGIN", false);
        this.$router.push("/login");
      });
    },
    quitLogin() {
      Dialog.alert({
        closeOnClickOverlay: true,
        message: "退出登录",
      }).then(() => {
        window.localStorage.clear();
        this.$store.commit("SET_TOKEN", "");
        this.$store.commit("SET_IS_LOGIN", false);
        this.$router.push("/login");
      });
    },
    collect() {
      this.$router.push({
        path: "/h5/collectPage",
      });
    },
    history() {
      this.$router.push({
        path: "/h5/footprintPage",
      });
    },
    contactUs() {
      this.$router.push({
        path: "/h5/contactUs",
      });
    },
    aboutUs() {
      this.$router.push({
        path: "/h5/AboutUs",
      });
    },
  },
};
</script>
<style lang="less">
.mine {
  min-height: calc(100vh);
  .copyright {
    text-align: center;
    font-size: 15px;
    margin: 15px auto;
    color: #666666;
  }
  .mine-order {
    display: flex;
    // align-items: center;
    flex-direction: column;
    background-color: #ffffff;
    padding: 20px;
    width: 85%;
    margin: 0 auto;
    border-radius: 10px;
    position: relative;
    top: -30px;

    .myOrder {
      font-size: 17px;
      color: #1a1a1a;
      //   font-weight: bold;
      margin-bottom: 15px;
    }

    .listOrderr {
      width: 100%;
      display: flex;

      .mine-order-item {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
        }

        span {
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
  }

  .mine-card {
    background: #fc6228;
    padding: 30px 15px;
    height: 180px;

    .mine-card-title {
      //   line-height: 70px;
      height: 100%;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      font-size: 16px;
      padding: 0;
      color: #ffffff;
    }

    .mine-icon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 35px 20px 20px;

      .mine-icon-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 25px;
          height: 25px;
        }

        span {
          color: #ffffff;
          font-size: 12px;
          margin-top: 5px;
        }
      }
    }
  }

  .mine-setting {
    margin: 10px 10px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    top: -30px;
  }

  .mine-card-setting {
    line-height: 70px;
    float: right;
    font-size: 18px;
  }

  .pass-form {
    width: 18rem;
    padding: 2rem 1rem;
  }
}
</style>
